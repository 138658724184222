<template>
  <div class="guideRight">
    <Affix :offset-top="0" @on-change="changeAffix">
      <div class="fixed-card">
        <div class="card">
          <Button
            type="primary"
            size="large"
            long
            v-if="showAffix"
            large
            @click="gotoDeclare"
            >立即申报</Button
          >

          <div class="c-title">发布部门</div>
          <div class="c-content">{{ childItem.deptName }}</div>
          <div class="flex-row-between btns">
            <Button type="primary" @click="attentionDepartment"
              >关注</Button
            >
            <Button type="success" @click="gotoDepartment"
              >查看</Button
            >
          </div>
        </div>
      </div>
    </Affix>
    <div class="content-box">
      <div
        class="relative-policy"
        v-if="policyList[childItem.id] && policyList[childItem.id].length > 0"
      >
        <div class="r-title">相关政策</div>
        <ul>
          <li
            class="r-li"
            v-for="(item, i) in policyList[childItem.id]"
            :key="i"
          >
            <a class="max-two-line r-name">{{ item.name }}</a>
            <div class="flex-row-between r-content">
              <span class="max-one-line r-dept">{{ item.areaname }}</span>
              <span class="r-remark">{{ item.time }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="relative-policy"
        v-if="
          projectList[childItem.deptCode] &&
          projectList[childItem.deptCode].length > 0
        "
      >
        <div class="r-title">相关专项</div>
        <ul>
          <li
            class="r-li"
            v-for="(item, i) in projectList[childItem.deptCode]"
            :key="i"
          >
            <a class="max-two-line r-name">{{ item.name }}</a>
            <div class="flex-row-between r-content">
              <span class="max-one-line r-dept">{{ item.deptName }}</span>
              <!-- <span class="r-remark">{{ item.remark }}</span> -->
              <span class="r-remark">资金支持</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import util from "../../utils/util";
export default {
  name: "guideRight",
  props: {
    baseItem: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters({
      childItem: "getCurrentDeclareItem",
    }),
  },
  watch: {
    childItem: function (newVal, oldval) {
      this.getProjects();
      this.getZhengce();
    },
  },
  data() {
    return {
      showAffix: false,
      policyList: {},
      projectList: {},
    };
  },
  mounted() {
    this.getProjects();
    this.getZhengce();
  },
  methods: {
    attentionDepartment() {
      this.$message({
        message: "功能开发中,敬请期待",
        type: "warning",
      });
    },
    gotoDepartment() {
      this.$message({
        message: "功能开发中,敬请期待",
        type: "warning",
      });
    },
    changeAffix(flag) {
      this.showAffix = flag;
    },
    gotoDeclare() {
      this.$emit("gotoDeclare");
    },
    getZhengce() {
      console.log("policylist:", this.policyList[this.childItem.id]);
      if (this.policyList[this.childItem.id] != null) {
        return;
      }
      this.$httpApi
        .get(`/dev-api/basicinfo/baseitem/policylist`, {
          pageNum: 1,
          pageSize: 20,
          sxid: this.childItem.id,
        })
        .then((res) => {
          console.log("相关政策:", res);
          if (res.code == 200) {
            let list = res.rows || [];
            list.forEach((element) => {
              element.time = util.dateFormat("YY-MM-DD", element.time);
            });
            this.policyList[this.childItem.id] = list;
          }
        })
		.catch((err) => {
		  
		});
    },
    getProjects() {
      console.log("相关项目:", this.projectList[this.childItem.deptCode]);
      if (this.projectList[this.childItem.deptCode] != null) {
        return;
      }
      this.$httpApi
        .get(`/dev-api/basicinfo/baseitem/getInterrelatedList`, {
          deptId: this.childItem.deptCode,
        })
        .then((res) => {
          console.log("相关项目:", res);
          if (res.code == 200) {
            this.projectList[this.childItem.deptCode] = res.rows;
          }
        })
		.catch((err) => {
		  
		});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/flex.scss";
.fixed-card {
  background: #ffffff;
  z-index: 999;
  padding: 20px 0;
}
.card {
  border: 1px solid rgb(226, 226, 226);
  padding: 20px;
  font-size: 12px !important;
  // margin-bottom: 20px;
  .c-title {
    font-size: 13px;
    border-bottom: 1px dotted rgb(226, 226, 226);
    padding: 10px 0;
    // margin-bottom: 14px;
  }
  .c-content {
    padding: 15px 0;
    font-size: 15px;
    color: #000;
    text-align: center;
  }
}
.relative-policy {
  border: 1px solid rgb(226, 226, 226);
  margin-bottom: 30px;
  .r-title {
    font-size: 13px;
    padding: 10px;
    color: #333;
  }
  .r-li {
    border-top: 1px solid rgb(226, 226, 226);
    padding: 12px 10px;
    .r-name {
      color: #000;
      font-size: 15px;
      width: 100%;
    }
    .r-content {
      font-size: 10px;
      color: #666;
      padding-top: 8px;
      .r-dept {
        margin-right: 16px;
      }
      .r-remark {
        color: #f00;
        flex-shrink: 0;
      }
      .r-date {
        flex-shrink: 0;
      }
    }
  }
}
</style>
