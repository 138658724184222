<template>
  <div class="guideCenter" v-if="childItem != null">
    <item-cell id="shenbaofangxiang" title="申报方向">
      <ul class="sbfx-items">
        <li
          v-for="(item, j) in baseItem.itemList"
          :key="j"
          @click="selectDirectionAction(j)"
        >
          <div
            :class="['flex-row', 'sbfx-cell', selectDirection == j ? 'sbfx-cell-on' : '']"
          >
            <div class="sbfx-days-box">
              <!-- 逻辑:
                 startTime减去当前时间 如果大于0则未开始，否则再用
                 endtime减去当前时间 如果大于0则显示剩余天数 否则已截止
              -->
              【<span class="sbfx-days">剩98天</span>】
            </div>
            <div class="sbfx-name max-one-line">
              {{ item.name }}
            </div>
            <span class="chooseActive" v-show="selectDirection == j">
              <i class="el-icon-check choose-icon"></i>
            </span>
          </div>
        </li>
      </ul>
    </item-cell>
    <item-cell id="jibenxinxi" title="基本信息">
      <TwoColumnTable :items="baseInfo"></TwoColumnTable>
    </item-cell>
    <item-cell id="banlibumen" title="办理部门">
      <div>主管部门: {{ childItem.deptName || "无" }}</div>
    </item-cell>
    <item-cell id="banlishijian" title="办理时间">
      <div>{{ childItem.startTime }} 至 {{ childItem.endtime }}</div>
      <div>办理时间段: {{ childItem.handerTime || "无" }}</div>
    </item-cell>
    <item-cell id="banjieshixian" title="办结时限">
      <div>承诺办结时限: {{ childItem.limitDays }}工作日</div>
    </item-cell>
    <item-cell id="zixundianhua" title="咨询电话">
      <Table :columns="zixunData.columns" :data="zixunData.data"></Table>
    </item-cell>
    <item-cell id="xiangmuxiaoshu" title="项目描述">
      <div>{{ childItem.exerciseContent || "无" }}</div>
    </item-cell>
    <item-cell id="banjiantiaojian" title="办件条件">
      <div v-html="childItem.acceptCondition"></div>
    </item-cell>
    <item-cell id="banlichengxu" title="办理程序">
      <Steps direction="vertical">
        <Step
          v-for="(item, j) in childItem.flowList"
          :key="j"
          :title="item.nodeName"
          :content="item.content"
          status="process"
        ></Step>
      </Steps>
    </item-cell>
    <!-- <item-cell id="dijiaoshuoming" title="递交说明">无</item-cell> -->
    <item-cell id="shoulichuangkou" title="受理窗口">
      办理形式: {{ childItem.handerType == 1 ? "窗口办理" : "网上办理" }}
    </item-cell>
    <item-cell id="shoufeibiaozhun" title="收费标准">{{
      childItem.charCrite || "无"
    }}</item-cell>
    <item-cell id="shoufeiyiju" title="收费依据">{{
      childItem.charAccor || "无"
    }}</item-cell>
    <item-cell id="tebieshuoming" title="特别说明">无</item-cell>
    <item-cell id="shenqingcailiao" title="申请材料">
      <Table :columns="cailiaoKeys" :data="childItem.clxxList"></Table>
      <div class="cailiao-tip">
        如需打印递交纸质材料，请等待主管部门发送通知后递交到受理窗口。<br />
        一、打印材料：包括项目申报书以及其他附件材料。<br /><br />
        二、具体做法：<br />
        1、材料准备：<br />
        1）项目申报书，必须在平台下载。如有疑问，请联系平台技术人员寻求帮助，联系方式：0757-83282211。<br />
        2）平台支持一键打包下载全部附件，也可以分别下载。 <br /><br />
        2、打印装订：<br />
        将下载的项目申报书（有水印）与其他附件材料，按各项目申报的要求，自行排版，打印装订。<br />
      </div>
    </item-cell>

    <item-cell id="shedingyiju" title="设定依据">
      {{ childItem.setupBasis || "无" }}
    </item-cell>
    <item-cell id="changjianwenti" title="常见问题">
      <div>
        {{ childItem.normalQuestion || "无" }}
      </div>
    </item-cell>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ItemCell from "@/components/guideItemCell";
import TwoColumnTable from "@/components/twoColumnTable";
export default {
  name: "guideCenter",
  components: { ItemCell, TwoColumnTable },
  props: {
    baseItem: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters({
      childItem: "getCurrentDeclareItem",
    }),
  },
  data() {
    return {
      selectDirection: 0,
      // childItem: null, // 选择哪个申报方向则设置baseItem.itemList对应的值给childItem
      banjie: null,
      cailiaoKeys: [
        {
          type: "index",
          width: 80,
          align: "center",
          title: "序号",
        },
        {
          title: "材料名称",
          key: "clname",
        },
        // {
        //   title: "上传要求",
        //   key: "age",
        // },
        // {
        //   title: "模板下载",
        //   width: 80,
        //   align: "center",
        //   key: "sfby",
        // },
        // {
        //   title: "其他信息",
        //   key: "sfrq",
        // },
      ],
      zixunData: {
        columns: [
          {
            title: "区划",
            key: "areaName",
          },
          {
            title: "联系人",
            key: "lxr",
          },
          {
            title: "联系方式",
            key: "tel",
          },
        ],
        data: [],
      },
      baseInfo: [
        // { title: "实施名称", content: "商务局" },
        // { title: "申报对象", content: "个人" },
        // { title: "扶持金额", content: "最高20万" },
        // { title: "办理时间", content: "2020-07-30至2021-03-23" },
        // { title: "咨询电话", content: "0755-89608767" },
      ],
    };
  },
  mounted() {
    if (this.childItem) {
      console.log("childItem", this.childItem);
      this.zixunData.data = this.childItem.zxfsList;
      this.baseInfo = [
        { title: "实施名称", content: this.childItem.implAgency || "" },
        { title: "申报对象", content: this.childItem.servObj == 1 ? "单位" : "个人" },
        { title: "扶持金额", content: this.childItem.capital || "资金扶持" },
        { title: "办理时间", content: this.childItem.handerTime || "" },
        { title: "咨询电话", content: this.childItem.advisoryTel || "" },
        { title: "监督电话", content: this.childItem.superviseTel || "" },
      ];
    }
  },
  methods: {
    selectDirectionAction(j) {
      if (j == this.selectDirection) {
        return;
      }
      this.selectDirection = j;
      let childItem = this.baseItem.itemList[j];
      console.log("child... change：", childItem);
      this.$store.commit("updateCurrentDeclareItem", childItem);
    },
  },
};
</script>

<style lang="scss" scoped>
.sbfx-items {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  .sbfx-cell {
    position: relative;
    padding: 16px 10px;
    border: 2px solid #f0f0f0;
    overflow: hidden;
    font-size: 14px;
    .sbfx-days-box {
      flex-shrink: 0;
      width: 90px;
      color: #999999;
      .sbfx-days {
        padding: 0 4px;
        color: #fd3938;
      }
      .sbfx-days-end {
        padding: 0 4px;
      }
    }
  }
  .sbfx-cell-on {
    border: 2px solid #4795f1;
  }
  .chooseActive {
    position: absolute;
    bottom: 0px;
    right: -20px;
    border-top: 20px solid #fff;
    border-right: 20px solid #fff;
    border-left: 20px solid #fff;
    border-bottom: 20px solid #4795f1;
    text-align: left;
    .choose-icon {
      position: absolute;
      bottom: -20px;
      color: #fff;
      right: -2px;
    }
  }
}
.cailiao-tip {
  margin-top: 20px;
  font-size: 13px;
  color: #000;
  background-color: #f0f0f0;
  border-radius: 6px;
  padding: 16px;
}
</style>
