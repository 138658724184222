<template>
  <div class="guide">
    <div class="banner-size">
      <div class="banner-limit banner-info">
        <span class="banner-fMax">项目详情</span>
        <span class="banner-fMin banner-other">送政策、送服务，助推企业发展</span>
      </div>
    </div>
    <div ref="guideContainer" class="guide-container width-limit">
      <div v-if="baseItem != null">
        <div class="menus">
          <div class="left-part">
            <Anchor show-ink :offset-top="offsetTop">
              <AnchorLink
                v-for="(menu, i) in menus"
                :key="i"
                :href="`#${menu.name}`"
                :title="menu.category"
              >
              </AnchorLink>
            </Anchor>
          </div>
          <div class="right-part">
            <div class="right-header">
              <div class="h-title max-two-line">
                {{ baseItem.name }}
              </div>
              <div class="h-btns">
                <Button type="primary" class="h-btn" @click="gotoDeclare"
                  >立即申报</Button
                >
                <!-- <Button icon="ios-download-outline" class="h-btn">关注</Button> -->
              </div>
            </div>
            <div class="flex-row-between menu-content">
              <div class="main-content">
                <GuideCenter ref="guideCenter" :baseItem="baseItem"></GuideCenter>
              </div>
              <div class="other-content">
                <GuideRight :baseItem="baseItem" @gotoDeclare="gotoDeclare"></GuideRight>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GuideCenter from "./guideCenter";
import GuideRight from "./guideRight";
import { Loading } from "element-ui";
export default {
  name: "guide",
  components: {
    GuideCenter,
    GuideRight,
  },
  computed: {
    ...mapGetters({
      childItem: "getCurrentDeclareItem",
      guideId: "getCurrentGuideId",
    }),
  },
  data() {
    return {
      offsetTop: 28,
      baseItem: null,
      /* 注意menus数组中的name要跟*/
      menus: [
        // {
        //   id: 0,
        //   name: "shixiangmingcheng",
        //   category: "事项名称",
        // },
        {
          id: 1,
          name: "shenbaofangxiang",
          category: "申报方向",
        },
        {
          id: 2,
          name: "jibenxinxi",
          category: "基本信息",
        },
        {
          id: 3,
          name: "banlibumen",
          category: "办理部门",
        },
        {
          id: 4,
          name: "banlishijian",
          category: "办理时间",
        },
        {
          id: 5,
          name: "banjieshixian",
          category: "办结时限",
        },
        {
          id: 6,
          name: "zixundianhua",
          category: "咨询电话",
        },
        {
          id: 7,
          name: "xiangmuxiaoshu",
          category: "项目描述",
        },
        {
          id: 8,
          name: "banjiantiaojian",
          category: "办件条件",
        },
        {
          id: 9,
          name: "banlichengxu",
          category: "办理程序",
        },
        // {
        //   id: 10,
        //   name: "dijiaoshuoming",
        //   category: "递交说明",
        // },
        {
          id: 11,
          name: "shoulichuangkou",
          category: "受理窗口",
        },
        {
          id: 12,
          name: "shoufeibiaozhun",
          category: "收费标准",
        },
        {
          id: 13,
          name: "shoufeiyiju",
          category: "收费依据",
        },
        {
          id: 14,
          name: "tebieshuoming",
          category: "特别说明",
        },
        {
          id: 15,
          name: "shenqingcailiao",
          category: "申请材料",
        },
        {
          id: 16,
          name: "shedingyiju",
          category: "设定依据",
        },
        {
          id: 17,
          name: "changjianwenti",
          category: "常见问题",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      console.log("guideId:", this.guideId);
      if (this.guideId) {
        let loadingInstance = Loading.service({
          target: this.$refs.guideContainer,
        });
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          this.$httpApi
            .get(`/dev-api/basicinfo/baseitem/${this.guideId}`, {})
            .then((res) => {
              console.log("baseItem:", res);
              loadingInstance.close();
              if (res.code == 200) {
                this.baseItem = res.data;
                this.$store.commit("updateCurrentDeclareItem", this.baseItem.itemList[0]);
              }
            })
			.catch((err) => {
			  loadingInstance.close()
			});
        });
      }
    },
    gotoDeclare() {
      if (this.$store.getters.getUserInfo) {
        let childItem = this.$refs.guideCenter.childItem;
        this.$store.commit("updateCurrentProjectItem", null);
        this.$store.commit("updateCurrentDeclareItem", childItem);
        this.getEnterpriseList();
        // this.$router.push({
        //   name: "declare",
        // });
      } else {
        this.$router.push({ name: "login" });
      }
    },
    /**
     * 企业用户基本信息列表
     */
    getEnterpriseList() {
      if (this.$store.getters.getUserInfo) {
        let url = "/dev-api/enterprise/baseInfo/list";
        // console.log("idxinxi....", this.$store.getters.getUserInfo)
        let params = {
          pageSize: 100, // 页大小
          pageNum: 0, // 页码
          webEid: this.$store.getters.getUserInfo.id,
        };
        // console.log("企业用户基本信息列表params>>>", params)
        this.$httpApi.get(url, params).then((res) => {
          console.log("判断是否需要引导>>>", res);
          if (res.code === 200 && res.rows.length == 0) {
            // 如果长度为0则需要提示补充基本信息
            this.$confirm("请您完善企业基本信息", "提示", {
              confirmButtonText: "完善",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push({
                  path: "/workplace/setting",
                });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "取消",
                });
              });
          } else if (res.code == 500 || res.code == 50014 || res.msg == '未登录') { 
              this.$store.commit("updateUserInfo", null);
              this.$store.commit("updateToken", null);
              this.$router.push({
                name: "login",
              });
          } else {
            console.log("关闭指引");
            this.$store.commit("updateAccountBaseInfo", res.rows[0]);
            setTimeout(() => {
              this.$router.push({
                name: "declare",
              });
            }, 300);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/index.scss";
.banner-size {
  background: url("../../assets/images/icon_banner_declare.png");
}
.banner-info {
  height: 100%;
  display: flex;
  align-items: center;
  .banner-other {
    margin-left: 15px;
  }
}

.guide-container {
  min-height: 500px;
  // padding: 0 40px;
  background: #ffffff;
  .menus {
    display: flex;
    flex-direction: row;
  }
  .left-part {
    width: 220px;
    padding-top: 150px;
  }
  .right-part {
    flex: 1;

    .right-header {
      margin-bottom: 20px;
      margin-left: 30px;
      .h-title {
        padding: 30px 0 10px 0;
        font-size: 24px;
        color: rgb(13, 28, 40);
        font-weight: 600;
        max-width: 700px;
        height: 90px;
        overflow: hidden;
      }
      .h-btn {
        margin-right: 10px;
      }
    }

    .menu-content {
      align-items: flex-start;
      .main-content {
        flex: 1;
        padding-left: 30px;
        padding-right: 30px;
      }
      .other-content {
        width: 260px;
      }
    }
  }
}

.ivu-anchor-link {
  font-size: 15px !important;
  margin-bottom: 6px;
}
.ivu-anchor-link-active {
  font-size: 15px !important;
}

.ivu-anchor-ink-ball {
  width: 3px !important;
  height: 20px !important;
  border: none !important;
  border-radius: 0;
  background-color: #2d8cf0 !important;
}
// .ivu-anchor-link-active {
//   position: relative;
// }
// .ivu-anchor-link-active::after {
//   content: " ";
//   position: absolute;
//   right: 35%;
//   top: 0;
//   width: 3px;
//   height: 30px;
//   background-color: #2d8cf0;
// }
</style>
